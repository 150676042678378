$eko_red: #DB052C;
$eko_dark_gray: #4A4A49;
$eko_mid_gray: #C5C5C5;
$eko_light_gray: #E2E5E8;
$eko_blue: #005696;
$white: #FFF;
$gold: #FFE39B;
$silver: #BFDCEA;
$bronze: #F3C9CA;

$info: #0085E8;
$success: #359414;
$warning: #FFB700;
$error: $eko_red;

//napraviti box-shadow
$spacing: 8px;
$inputwidth: 240px;
