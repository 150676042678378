.choice {
	margin-top: 32px;
	margin-bottom: 48px;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: space-between;

	p {
		font-size: 14px;
		font-weight: 400;
		margin: 32px 0 16px 0;
	}
}

.img {
	height: 270px;
}

.info {
	display: flex;
	flex-direction: column;
	align-items: center;

	button:last-child {
		margin-top: 8px;
	}
}

.flip_continer {
	position: relative;
	width: 240px;
	height: 46px;
	margin-top: 8px;
}

.btn_flip {
	width: 100%;
	height: 100%;
	position: absolute;
	transform-style: preserve-3d;
	transition: all 0.3s ease-out;
}

.btn {
	position: absolute;
	backface-visibility: hidden;
	width: 100%;
	height: 100%;
}

.help {
	position: absolute;
	backface-visibility: hidden;
	width: 100%;
	height: 100%;
	background: #1DBA2D;
	border-radius: 4px;
	color: #FFF;
	text-align: center;
	padding: 1px;
	transform: rotateX(180deg);
	cursor: pointer;
	transition: box-shadow .25s ease-in-out;
	border: 1px solid #1DBA2D;

	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	}
}

.rotate {
	transform: rotateX(180deg);
}

.phone_icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.google {
	width: 120px;
	height: 55.5px;
	padding: 10.5px;
}

.app_store {
	padding: 7.5px;
	width: 120px;
	height: 55px;
}

.download {
	display: flex;
	align-items: center;
}

.title {
	padding: 0px 8px 8px 8px;
	text-align: center;
	font-weight: 500;
}
