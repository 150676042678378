@import '../../../scss_utils/vars';

h1,
h2,
p {
	margin: 0;
}

p {
	font-size: 14px;
	font-weight: 300;
	line-height: 120%;
}

.btn {
	border: 1px solid;
	border-radius: $spacing / 2;
	font-family: 'PFDinDisplayPro', sans-serif;
	font-weight: bold;
	font-size: 14px;
	line-height: 41px;
	display: flex;
	flex: row wrap;
	align-items: center;
	cursor: pointer;
	transition: box-shadow 0.25s ease-in-out;
	z-index: 5;
	outline: none;

	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.btn_regular {
	@extend .btn;
	padding: 0 16px;
	background-color: white;
	color: $eko_dark_gray;
	border-color: $eko_dark_gray;
}

.btn_regular_login {
	@extend .btn;
	padding: 0 8px;
}

.btn_regular_menu {
	@extend .btn;
	padding: 0;
	background-color: white;
	color: $eko_dark_gray;
	border-color: $eko_dark_gray;
}

.btn_close {
	@extend .btn;
	padding: 0;
	background-color: transparent;
	color: #fff;
	border-color: #fff;
}

.btn_close_promo {
	@extend .btn;
	padding: 0;
	background-color: $eko_red;
	color: #fff;
	border-color: $eko_red;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
	width: 44px;
	height: 44px;
}

.btn_cart_standard {
	@extend .btn;
	padding: 0;
	background-color: $eko_red;
	color: #fff;
	border-color: $eko_red;
}

.btn_cart_premium {
	@extend .btn;
	padding: 0;
	background-color: #606f8c;
	color: #fff;
	border-color: #606f8c;
}

.btn_regular_back {
	@extend .btn;
	padding: 0 16px 0 0;
	background-color: white;
	color: $eko_dark_gray;
	border-color: $eko_dark_gray;
}

.btn_negative_back {
	@extend .btn;
	padding: 0 16px 0 0;
	background-color: white;
	color: $eko_red;
	border-color: $eko_red;

	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	}
}

.btn_regular_forward {
	@extend .btn;
	padding: 0 0 0 16px;
	background-color: white;
	color: $eko_dark_gray;
	border-color: $eko_dark_gray;
	height: 44px;
}

.btn_preffered_forward {
	@extend .btn;
	padding: 0 0 0 16px;
	background-color: $eko_red;
	color: white;
	border-color: $eko_red;
	height: 46px;

	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	}
}

.btn_premium {
	@extend .btn;
	padding: 0 0 0 16px;
	background-color: #050505;
	color: white;
	border-color: #050505;
}

.btn_regular_iconleft {
	@extend .btn_regular;
	width: $inputwidth;
	padding: 0;
}

.btn_regular_iconright {
	@extend .btn_regular_iconleft;
	padding: 0 0 0 16px;
	justify-content: space-between;
}

.btn_regular_iconright_transparent {
	@extend .btn_regular_iconleft;
	background-color: transparent;
	color: #fff;
	border-color: #fff;
	padding: 0 0 0 16px;
	justify-content: space-between;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.btn_preffered {
	@extend .btn;
	background-color: $eko_red;
	color: white;
	border-color: $eko_red;
}

.btn_preffered_confirm {
	@extend .btn;
	background-color: $eko_red;
	color: white;
	border-color: $eko_red;
	width: 122px;
	justify-content: center;

	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	}
}

.btn_preffered_iconleft {
	@extend .btn_preffered;
	width: $inputwidth;
	padding: 0;
}

.btn_preffered_iconright {
	@extend .btn_preffered_iconleft;
	padding: 0 0 0 16px;
	justify-content: space-between;
	transition: box-shadow 0.25s ease-in-out;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	}
}

.btn_preffered_icon {
	@extend .btn_preffered;
	width: auto;
	padding: 0;
}

.btn_negative {
	@extend .btn;
	background-color: white;
	color: $eko_red;
	border-color: $eko_red;
}

.btn_negative_iconright {
	@extend .btn_negative;
	width: $inputwidth;
	padding: 0 0 0 16px;
	justify-content: space-between;
}

.btn_option {
	@extend .btn;
	background-color: $eko_dark_gray;
	color: white;
	border-color: $eko_dark_gray;
}

.btn_option_icon {
	@extend .btn_option;
	padding: 0 0 0 16px;
	justify-content: space-between;
}

.btn_disabled {
	@extend .btn;
	background-color: $eko_light_gray;
	color: $eko_mid_gray;
	border-color: $eko_light_gray;
}

.btn_icon {
	padding: 14px;
	box-sizing: border-box;
}
