@import '../src/scss_utils/vars';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

* {
	box-sizing: border-box;
}

h1 {
	display: flex;
	align-items: center;
	min-height: 44px;
	line-height: 90%;
	font-weight: 700;
	font-size: 24px;
	color: $eko_red;
}
h2 {
	font-weight: 500;
	font-size: 18px;
	color: $eko_dark_gray;
}

@font-face {
	font-family: PFDinDisplayPro;
	src: local("PFDinDisplayPro"),
		url(../src/assets/fonts/PFDinDisplayPro-Light.ttf);
	font-weight: 300;
}

@font-face {
	font-family: PFDinDisplayPro;
	src: local("PFDinDisplayPro"),
		url(../src/assets/fonts/PFDinDisplayPro-Medium.ttf);
	font-weight: 500;
}

@font-face {
	font-family: PFDinDisplayPro;
	src: local("PFDinDisplayPro"),
		url(../src/assets/fonts/PFDinDisplayPro-Bold.ttf);
	font-weight: 700;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'PFDinDisplayPro', sans-serif;
	color: #4A4A49;
	background: url('./assets/images/bg-lines.png');
	background-repeat: repeat-x;
	background-size: 6px auto;
	height: 100%;
}

.react-datepicker {
	font-family: 'PFDinDisplayPro', sans-serif !important;
}

.react-datepicker-popper {
	z-index: 50 !important;
}

.react-datepicker__triangle {
	display: none !important;
}

.react-datepicker__day--keyboard-selected {
	background-color: #DB052C !important;
}

.react-datepicker__input-container {
	width: 100% !important;
}

.react-datepicker-wrapper {
	width: 100% !important;
}

.react-datepicker__year-dropdown {
	overflow-y: scroll !important;
}

.react-datepicker__header {
	background-color: #DB052C !important;
}

.react-datepicker__month-select {
	padding: 3px !important;
	border-radius: 2px !important;
	background-color: #E2E5E8 !important;
}

.react-datepicker__year-select {
	padding: 3px !important;
	border-radius: 2px !important;
	background-color: #E2E5E8 !important;
}

.slick-dots {
	li {
		margin: 0;

		&.slick-active {
			button:before {
				opacity: 1;
				color: #DB052C;
			}
		}

		button:before {
			opacity: 0.8;
			color: #4A4A49;
			font-size: 9px;
		}
	}
}

.rc-progress-line {
	border-radius: 4px;
}

.icon {
	font-size: 20px;
	padding: 12px;
	border-radius: 4px;
}

.message {
	margin: 30px;

	width: 240px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	border-radius: 4px;
	color: white;
}

.checkbox {
	border: 1px solid $eko_light_gray;
	position: relative;
	@extend .message;
	width: 240px;

	i {
		position: relative;
		font-size: 20px;
		color: $eko_red;
		padding: 0;
		margin: 14px;

		&:after {
			content: '';
			position: absolute;
			width: 18px;
			height: 18px;
			left: 1px;
			top: 1px;
			border-radius: 10px;
			background: $eko_light_gray;
			opacity: 1;
			transition: all .25s ease-in-out;
		}
	}

	label {
		color: $eko_dark_gray;
		font-weight: 300;
	}

	input[type=checkbox] {
		width: auto;
		position: absolute;
		left: 12px;
		top: 12px;
		z-index: 5;
		opacity: 0;
		cursor: pointer;
		transform: scale(1.3);
		
		&:checked {
			~ .icon-success {
				&:after {
					opacity: 0;
				}
				
				&:before {
					content: '';
					position: absolute;
					border-radius: 20px;
					box-shadow: 0 4px 8px rgba(0 ,0 , 0, 0.15);
					width: 18px;
					height: 18px;
					top: 0px;
					left: 0px;
				}
			}
		}
	}
}

#root {
	//always center the first div to the center
	#main {
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		height: 100vh;

		> p {
			line-height: 44px;
			margin-top: auto;
			text-align: center;
		}

		.smile_clubcard {
			margin: 55px 0;
			transform: rotate(-15deg);
		}
	}
}

// Material Design Icons
.face {
    position:relative;
    display:inline-block;

	&:after {
    content: "face";

	}
}

.expand_more {
	    position:relative;
    display:inline-block;

	&:after {
    content: "expand_more";

	}
}

.more_horiz {
	    position:relative;
    display:inline-block;

	&:after {
    content: "more_horiz";

	}
}

.home {
	    position:relative;
    display:inline-block;

	&:after {
    content: "home";

	}
}

.library_books {
	    position:relative;
    display:inline-block;

	&:after {
    content: "library_books";

	}
}

.whatshot {
	    position:relative;
    display:inline-block;

	&:after {
    content: "whatshot";

	}
}

.sentiment_satisfied_alt {
	    position:relative;
    display:inline-block;

	&:after {
    content: "sentiment_satisfied_alt";

	}
}

.list {
	    position:relative;
    display:inline-block;

	&:after {
    content: "list";

	}
}

.compare_arrows {
	    position:relative;
    display:inline-block;

	&:after {
    content: "compare_arrows";

	}
}

.favorite_border {
	    position:relative;
    display:inline-block;

	&:after {
    content: "favorite_border";

	}
}

.forum {
	    position:relative;
    display:inline-block;

	&:after {
    content: "forum";

	}
}

.vpn_key {
	    position:relative;
    display:inline-block;

	&:after {
    content: "vpn_key";

	}
}

.map {
	    position:relative;
    display:inline-block;

	&:after {
    content: "map";

	}
}

.playlist_add_check {
	    position:relative;
    display:inline-block;

	&:after {
    content: "playlist_add_check";

	}
}

.mail_outline {
	    position:relative;
    display:inline-block;

	&:after {
    content: "mail_outline";

	}
}

.settings {
	    position:relative;
    display:inline-block;

	&:after {
    content: "settings";

	}
}

.block {
	    position:relative;
    display:inline-block;

	&:after {
    content: "block";

	}
}

.phone {
	    position:relative;
    display:inline-block;

	&:after {
    content: "phone";

	}
}

.arrow_forward {
	    position:relative;
    display:inline-block;

	&:after {
    content: "arrow_forward";

	}
}

.help_outline {
	    position:relative;
    display:inline-block;

	&:after {
    content: "help_outline";

	}
}

.arrow_back {
	    position:relative;
    display:inline-block;

	&:after {
    content: "arrow_back";

	}
}

.textsms {
	    position:relative;
    display:inline-block;

	&:after {
    content: "textsms";

	}
}

.close {
	    position:relative;
    display:inline-block;

	&:after {
    content: "close";

	}
}

.block {
	    position:relative;
    display:inline-block;

	&:after {
    content: "block";

	}
}

.mail_outline {
	    position:relative;
    display:inline-block;

	&:after {
    content: "mail_outline";

	}
}

.public {
	    position:relative;
    display:inline-block;

	&:after {
    content: "public";

	}
}

.share {
	    position:relative;
    display:inline-block;

	&:after {
    content: "share";

	}
}

.info {
	    position:relative;
    display:inline-block;

	&:after {
    content: "info";

	}
}

.save {
	    position:relative;
    display:inline-block;

	&:after {
    content: "save";

	}
}

.check {
	    position:relative;
    display:inline-block;

	&:after {
    content: "check";

	}
}

.person {
	    position:relative;
    display:inline-block;

	&:after {
    content: "person";

	}
}

.card_giftcard {
	    position:relative;
    display:inline-block;

	&:after {
    content: "card_giftcard";

	}
}

.error_outline {
	    position:relative;
    display:inline-block;

	&:after {
    content: "error_outline";

	}
}