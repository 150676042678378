.game_terms {
	padding: 24px 16px;
	height: 100%;
	width: 100%;
	overflow: auto;
	scroll-behavior: smooth;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	a {
		color: #db052c;
		font-weight: 700;
		text-decoration: none;
	}

	p {
		color: #4a4a49;
	}

	h2 {
		text-align: center;
	}

	ul {
		color: #4a4a49;
		font-size: 14px;
		font-weight: 300;
		line-height: 120%;
		margin: 0px;
		padding-left: 24px;

		li {
			margin-bottom: 6px;
		}
	}
}

.about_list_item {
	display: flex;
	flex-direction: column;
	margin: 8px 0px;

	&:first-of-type {
		margin: 16px 0px 8px 0px;
	}

	h2 {
		text-align: left;
		text-decoration: underline;
		margin-bottom: 8px;
	}

	thead {
		p {
			color: #fff;
		}
	}

	table {
		td {
			h2 {
				text-decoration: none;
				margin-bottom: 0px;
			}
		}

		td[colSpan='2'] {
			p {
				text-align: center;
			}
		}
	}
}

.title {
	font-size: 18px;
	font-weight: 500;
}

.content {
	margin: 8px 0;
	text-align: justify;
}

.table {
	border-collapse: collapse;
	width: 100%;

	td,
	th {
		border: 1px solid #ddd;
		padding: 8px;
		text-align: left;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	tr:hover {
		background-color: #e2e5e8;
	}

	th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		background-color: #db052c;
		color: white;
	}
}

.btn_wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 32px 0px;

	button:first-of-type {
		width: calc(35% - 8px);
		justify-content: center;
		height: 46px;
	}

	button:last-of-type {
		width: calc(65% - 8px);
	}
}

.olp_header {
	margin-top: 36px;
}
